import React from "react"
import { navigate } from "gatsby"
import { Stack, Button } from "@chakra-ui/react"
import { GoChevronLeft, GoChevronRight } from "react-icons/go"

interface Props {
  baseUrl: string
  currentPage: number
  hasNextPage: boolean
  hasPreviousPage: boolean
  pageCount: number
}

export const Pagination: React.FC<Props> = ({
  baseUrl,
  currentPage,
  hasNextPage,
  hasPreviousPage,
  pageCount,
}) => {
  return (
    <Stack direction="row">
      <Button
        size={`sm`}
        bg={`transparent`}
        isDisabled={!hasPreviousPage}
        onClick={() =>
          navigate(`${baseUrl}/${currentPage - 1 === 1 ? "" : currentPage - 1}`)
        }
      >
        <GoChevronLeft />
      </Button>
      {Array.from({ length: pageCount }, (_, index) => {
        return (
          <Button
            size={`sm`}
            isDisabled={currentPage === index + 1}
            _disabled={{
              bg: "black",
              color: "white",
            }}
            bg={`transparent`}
            color={`black`}
            rounded={0}
            _hover={{
              _disabled: { bg: "blackAlpha.500" },
              bg: "blackAlpha.500",
            }}
            onClick={() =>
              navigate(`${baseUrl}/${index + 1 === 1 ? "" : index + 1}`)
            }
          >
            {index + 1}
          </Button>
        )
      })}
      <Button
        size={`sm`}
        bg={`transparent`}
        isDisabled={!hasNextPage}
        onClick={() => navigate(`${baseUrl}/${currentPage + 1}`)}
      >
        <GoChevronRight />
      </Button>
    </Stack>
  )
}
