import React from "react"
import { Link as GL } from "gatsby"

import {
  Heading,
  Stack,
  Box,
  GridItem,
  Image,
  LinkBox,
  LinkOverlay,
} from "@chakra-ui/react"

interface Props {
  article: any
  imageHeight?: string | number
}

export const BlogCard: React.FC<Props> = ({ article, imageHeight }) => {
  return (
    <GridItem as={`article`} w="100%">
      <LinkBox>
        <Stack spacing={0}>
          {article.featuredPhoto && (
            <Image
              src={article.featuredPhoto.file.url}
              h={imageHeight || 48}
              objectFit={`fill`}
            />
          )}

          <Box py={4} px={4} pb={4} bg={`white`} color={`#212720`}>
            <LinkOverlay as={GL} to={`/articles/${article.slug}`}>
              <Heading fontSize={15} lineHeight={1.8}>
                {article.title}
              </Heading>
            </LinkOverlay>
          </Box>
        </Stack>
      </LinkBox>
    </GridItem>
  )
}

export default BlogCard
