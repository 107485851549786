import React from "react"
import { graphql } from "gatsby"
import {
  Container,
  Stack,
  Grid,
  Text,
  Heading,
  Image,
  LinkBox,
  LinkOverlay,
  GridItem,
} from "@chakra-ui/react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Pagination } from "../components/partials/pagination"
import BlogCard from "../components/partials/blog-card"
import SubpageParallaxImageHero from "../components/partials/subpage-parallax-image-hero"
import DisplayVerticalLongAd from "../components/ads/display-vertical-long-ad"
import BlogCardAd from "../components/ads/blog-card-ad"
import LatestCardAd from "../components/ads/latest-card-ad"

const Services: React.FC<any> = ({ data, pageContext, location: loc }) => {
  const path = loc?.pathname || ""
  const { currentPage, hasNextPage, hasPreviousPage, pageCount } =
    data.allContentfulArticles.pageInfo
  const { latestArticles } = pageContext

  return (
    <Layout>
      <Seo
        title="ブログ記事一覧"
        description="ブログ記事一覧"
        image={data.allContentfulSiteImages.edges[0].node.image.url}
      />
      {data.allContentfulSiteImages.edges.map(({ node: siteImage }: any) => (
        <SubpageParallaxImageHero
          url={siteImage.image.url}
          caption={`ブログ記事一覧`}
          fontFamily="sans"
        />
      ))}
      <Container maxW="container.xl" as={`main`} p={{ base: 4, lg: 0 }}>
        {data && (
          <Stack pb={{ base: 8, lg: 24 }} pt={{ base: 8, lg: 24 }} spacing={8}>
            <Stack direction={{ base: "column", lg: `row` }} spacing={8}>
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  lg: "repeat(2, 1fr)",
                }}
                w={{ base: "100%", lg: `70%` }}
                gap={6}
              >
                <GridItem as={`article`} w="100%">
                  <BlogCardAd path={path} />
                </GridItem>
                {data.allContentfulArticles.edges.map(
                  ({ node: article }: any) => {
                    return <BlogCard article={article} imageHeight={64} />
                  }
                )}
              </Grid>
              <Stack w={{ base: "100%", lg: `30%` }}>
                <Stack spacing={6}>
                  <Heading fontSize={20} fontWeight={`normal`}>
                    最近の記事
                  </Heading>
                  <LatestCardAd path={path} />
                  {latestArticles.data.allContentfulArticles.edges.map(
                    ({ node: article }: any) => {
                      return (
                        <LinkBox key={article.id}>
                          <Stack bg={`blackAlpha.50`} direction={`row`}>
                            <Image
                              src={article.featuredPhoto.file.url}
                              alt={article.title}
                              w={36}
                              h={24}
                            />
                            <LinkOverlay href={`/articles/${article.slug}/`}>
                              <Text fontSize={12} py={4} px={2}>
                                {article.title}
                              </Text>
                            </LinkOverlay>
                          </Stack>
                        </LinkBox>
                      )
                    }
                  )}
                </Stack>
                <Stack py={4}>
                  <DisplayVerticalLongAd path={path} />
                </Stack>
              </Stack>
            </Stack>

            <Pagination
              baseUrl={`/articles`}
              currentPage={currentPage}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
              pageCount={pageCount}
            />
          </Stack>
        )}
      </Container>
    </Layout>
  )
}

export default Services

export const blogListQuery = graphql`
  query GetArticles($skip: Int!, $limit: Int!, $published: Boolean!) {
    allContentfulSiteImages(filter: { imageNo: { eq: 35 } }, limit: 1) {
      edges {
        node {
          image {
            url
          }
        }
      }
    }
    allContentfulArticles(
      limit: $limit
      filter: { published: { eq: $published } }
      sort: { order: DESC, fields: createdAt }
      skip: $skip
    ) {
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        totalCount
        pageCount
      }
      edges {
        node {
          category {
            name
          }
          content {
            raw
          }
          createdAt(formatString: "YYYY/MM/DD HH:mm:ss")
          featuredPhoto {
            file {
              url
            }
          }
          tags {
            name
          }
          title
          slug
          updatedAt(formatString: "YYYY/MM/DD HH:mm:ss")
        }
      }
    }
  }
`
