import React, { useEffect } from "react"

declare global {
  interface Window {
    adsbygoogle: any[]
  }
}

const DisplayVerticalLongAd: React.FC<any> = ({ path }) => {
  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [path])

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client="ca-pub-9783706503637594"
      data-ad-slot="1087540447"
      data-ad-format="auto"
      data-full-width-responsive="true"
    ></ins>
  )
}

export default DisplayVerticalLongAd
