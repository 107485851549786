import React, { useEffect } from "react"

declare global {
  interface Window {
    adsbygoogle: any[]
  }
}

const BlogCardAd: React.FC<any> = ({ path }) => {
  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [path])

  return (
    <ins
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-format="fluid"
      data-ad-layout-key="-6t+ed+2i-1n-4w"
      data-ad-client="ca-pub-9783706503637594"
      data-ad-slot="3336684866"
    ></ins>
  )
}

export default BlogCardAd
